import menu from './components/menu'
import animation from './components/animation'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    menu();
    window.onload = () => {
      animation();
    }
  },
  false
)
