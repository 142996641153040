const menu = () => {
  const menuBtn = document.querySelector('.js-menu');
  const nav = document.querySelector('.js-nav');
  const anchorNav = document.querySelectorAll('.js-nav a')

  if(menuBtn && nav) {
    menuBtn.addEventListener('click', () => {
      menuBtn.classList.toggle('active');
      nav.classList.toggle('active');
    })

    if(anchorNav) {
      anchorNav.forEach(item => {
        item.addEventListener('click', () => {
          menuBtn.classList.remove('active');
          nav.classList.remove('active');
        })
      })
    }
  }
}

export default menu
