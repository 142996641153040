const animation = () => {
  //@ts-ignore
  const wow = new WOW(
    {
      offset: 100,
    }
  )
  wow.init();
}

export default animation
